import { CloseOutlined } from "@ant-design/icons"
import { Drawer, Menu } from "antd"
import React, { FC, useEffect, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { NavLink, useLocation } from "react-router-dom"

import { useApp } from "src/app"
import { useHeaderQuery } from "src/graphql"
import { ReactComponent as BurgerMenuSvg } from "src/icons/burger-menu.svg"
import { ReactComponent as MobileLogoDarkTheme } from "src/icons/mobile-logo-light.svg"
import "./index.less"

export const HeaderMenu: FC = () => {
  const isTablet = useMediaQuery({ maxWidth: 913 })
  const { pathname, hash } = useLocation()
  const lastHash = useRef("")

  const { user } = useApp()

  const { data } = useHeaderQuery()

  const [open, setOpen] = useState(false)

  const headerData = data?.header?.data?.attributes?.links || []

  const menuItems = headerData
    .slice(1)
    .filter(it => {
      if (user.authenticated) {
        return it?.url !== "/sign-in" && it?.url !== "/sign-up"
      } else {
        return it?.url !== "/dashboard"
      }
    })
    .map(item => ({
      key: item?.url ?? "/",
      label: <NavLink to={item?.url ?? "/"} onClick={() => setOpen(false)}>{item?.title}</NavLink>,
    }))

  useEffect(() => {
    if (hash) {
      lastHash.current = hash.slice(1)
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: "smooth", block: "start" })
        lastHash.current = ""
      }, 0)
    }
  }, [hash])

  return (
    <React.Fragment>
      {isTablet ? (
        <React.Fragment>
          <button className={"burger-menu-button"} onClick={() => setOpen(true)}>
            <BurgerMenuSvg />
          </button>
          <Drawer
            maskClosable={false}
            size={"large"}
            style={{ backgroundColor: "#12212b" }}
            className={"drawer-container"}
            styles={{ header: { border: "none" }, footer: { border: "none" } }}
            closeIcon={false}
            width={"100vw"}
            placement={"right"}
            onClose={() => setOpen(false)}
            open={open}
          >
            <div className={"drawer-header"}>
              <MobileLogoDarkTheme />
              <CloseOutlined onClick={() => setOpen(false)} />
            </div>
            <Menu
              theme={"light"}
              className={"navigation-menu"}
              mode={"vertical"}
              activeKey={pathname}
              items={menuItems}
            />
          </Drawer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Menu
            theme={"light"}
            className={"navigation-menu"}
            mode={"horizontal"}
            activeKey={pathname}
            items={menuItems}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
