import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Answer",
      "AssignedMember",
      "BrandingTheme",
      "Company",
      "ComponentDataAddress",
      "ComponentDataContactPerson",
      "ComponentDataEntry",
      "ComponentDataFinancial",
      "ComponentDataFullAddress",
      "ComponentDataJobMembers",
      "ComponentDataPaymentTerms",
      "ComponentDataSales",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiLocation",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSocial",
      "ComponentUiStep",
      "ComponentUiTab",
      "ComponentUiText",
      "Contact",
      "Currency",
      "EmailDesignerEmailTemplate",
      "Footer",
      "Form",
      "FormEmail",
      "Header",
      "Home",
      "Integration",
      "Invoice",
      "InvoiceEmail",
      "InvoiceItem",
      "Job",
      "JobStatus",
      "Layout",
      "NotFound",
      "Pricing",
      "PrivacyPolicy",
      "PushNotification",
      "Question",
      "Quote",
      "QuoteEmail",
      "QuoteItem",
      "ReactIconsIconlibrary",
      "RegistrationRequest",
      "ResponseInstance",
      "Service",
      "Subscription",
      "Task",
      "TaxAmount",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Answer",
      "AssignedMember",
      "BrandingTheme",
      "Company",
      "ComponentDataAddress",
      "ComponentDataContactPerson",
      "ComponentDataEntry",
      "ComponentDataFinancial",
      "ComponentDataFullAddress",
      "ComponentDataJobMembers",
      "ComponentDataPaymentTerms",
      "ComponentDataSales",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiLocation",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSocial",
      "ComponentUiStep",
      "ComponentUiTab",
      "ComponentUiText",
      "Contact",
      "Currency",
      "EmailDesignerEmailTemplate",
      "Footer",
      "Form",
      "FormEmail",
      "Header",
      "Home",
      "Integration",
      "Invoice",
      "InvoiceEmail",
      "InvoiceItem",
      "Job",
      "JobStatus",
      "Layout",
      "NotFound",
      "Pricing",
      "PrivacyPolicy",
      "PushNotification",
      "Question",
      "Quote",
      "QuoteEmail",
      "QuoteItem",
      "ReactIconsIconlibrary",
      "RegistrationRequest",
      "ResponseInstance",
      "Service",
      "Subscription",
      "Task",
      "TaxAmount",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const CurrencyFragmentDoc = gql`
    fragment Currency on Currency {
  code
  description
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const UsersPermissionsRoleFragmentDoc = gql`
    fragment UsersPermissionsRole on UsersPermissionsRoleEntity {
  id
  attributes {
    name
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUser {
  id
  position
  email
  phone
  username
  firstName
  lastName
  mobileNotifications
  desktopNotifications
  status
  createdAt
  avatar {
    data {
      id
      attributes {
        name
        url
      }
    }
  }
  role {
    data {
      ...UsersPermissionsRole
    }
  }
  company {
    data {
      id
    }
  }
  billingAddress
  deliveryAddress
  push_notifications {
    data {
      id
      attributes {
        title
        body
        delivered
      }
    }
  }
  subscription {
    data {
      attributes {
        type
      }
    }
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
    size
    name
  }
}
    `;
export const BrandingThemeFragmentDoc = gql`
    fragment BrandingTheme on BrandingThemeEntity {
  id
  attributes {
    name
    active
    contactDetails
    footer
    termsAndPaymentAdvice
    logo {
      data {
        ...File
      }
    }
    company {
      data {
        id
      }
    }
    logoPosition
    showInvoiceNumber
    showInvoiceStatus
    showQuoteNumber
    showQuoteStatus
    showLogo
    showFooter
    showPaymentAdvice
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  name
  xeroClientId
  xeroLegalName
  businessRegistrationNumber
  users {
    data {
      id
      attributes {
        ...User
      }
    }
  }
  branding_themes {
    data {
      ...BrandingTheme
    }
  }
  xeroWebhookSecret
  xeroDataAccess
  lineOfBusiness
  xeroClientSecretError
  subscription {
    data {
      attributes {
        stripeID
        trialEnds
      }
    }
  }
}
    `;
export const ServiceFragmentDoc = gql`
    fragment Service on Service {
  name
  salePrice
  description
  costPrice
}
    `;
export const QuoteItemFragmentDoc = gql`
    fragment QuoteItem on QuoteItemEntity {
  id
  attributes {
    name {
      data {
        id
        attributes {
          ...Service
        }
      }
    }
    description
    quantity
    price
    unitAmount
    tax
  }
}
    `;
export const InvoiceItemFragmentDoc = gql`
    fragment InvoiceItem on InvoiceItemEntity {
  id
  attributes {
    description
    price
    quantity
    name {
      data {
        id
        attributes {
          name
        }
      }
    }
    invoice {
      data {
        id
      }
    }
  }
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on Invoice {
  reference
  date
  amountDue
  amountPaid
  invoiceNumber
  dueDate
  sentToContact
  currency
  status
  terms
  quote {
    data {
      id
    }
  }
  company {
    data {
      id
      attributes {
        name
      }
    }
  }
  contact {
    data {
      id
      attributes {
        name
        firstName
        lastName
        email
        website
        ACN
        ABN
        notes
        accountNumber
        status
        billingFullAddress {
          formattedAddress
          latitude
          longitude
        }
      }
    }
  }
  job {
    data {
      id
    }
  }
  invoiceItems {
    data {
      ...InvoiceItem
    }
  }
  files {
    data {
      ...File
    }
  }
  taxAmount {
    data {
      id
      attributes {
        tax
        percent
      }
    }
  }
}
    `;
export const QuoteFragmentDoc = gql`
    fragment Quote on Quote {
  address {
    longitude
    latitude
    formattedAddress
  }
  quoteAddress
  reference
  quoteNumber
  onHireDate
  offHireDate
  currency
  terms
  status
  address {
    formattedAddress
    latitude
    longitude
  }
  totalAmount
  createdAt
  company {
    data {
      id
      attributes {
        name
      }
    }
  }
  contact {
    data {
      id
      attributes {
        name
        firstName
        lastName
        email
        website
        ACN
        ABN
        notes
        accountNumber
        status
        billingFullAddress {
          formattedAddress
          latitude
          longitude
        }
      }
    }
  }
  items {
    data {
      ...QuoteItem
    }
  }
  job {
    data {
      id
    }
  }
  files {
    data {
      ...File
    }
  }
  taxAmount {
    data {
      id
      attributes {
        tax
        percent
      }
    }
  }
  invoice {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
}
    `;
export const FinancialFragmentDoc = gql`
    fragment Financial on ComponentDataFinancial {
  id
  taxIdNumber
  bankAccountName
  bankAccountNumber
  currency
  details
}
    `;
export const SalesFragmentDoc = gql`
    fragment Sales on ComponentDataSales {
  id
  discount
  creditLimitAmount
  salesAccount
  amountAre
  gst
  payment {
    id
    type
    day
  }
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on ComponentDataAddress {
  AddressType
  AddressLine1
  AddressLine2
  City
  Country
  PostalCode
  Region
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  name
  firstName
  lastName
  email
  website
  ACN
  ABN
  notes
  accountNumber
  status
  jobs {
    data {
      id
    }
  }
  quotes {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
  invoices {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
  financial {
    ...Financial
  }
  sales {
    ...Sales
  }
  avatar {
    data {
      attributes {
        url
      }
    }
  }
  phones {
    value
  }
  addresses {
    ...Address
  }
  persons {
    id
    email
    name
  }
  deliveryAddress
  billingAddress
  billingFullAddress {
    formattedAddress
    latitude
    longitude
  }
}
    `;
export const JobStatusFragmentDoc = gql`
    fragment JobStatus on JobStatusEntity {
  id
  attributes {
    title
    color
  }
}
    `;
export const AnswerFragmentDoc = gql`
    fragment Answer on AnswerEntity {
  id
  attributes {
    answerText
    questionText
    title
    question {
      data {
        id
      }
    }
    media {
      data {
        ...File
      }
    }
    signature
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  title
  type
  questionText
  required
  isMedia
  answers {
    data {
      ...Answer
    }
  }
}
    `;
export const ResponseInstanceFragmentDoc = gql`
    fragment ResponseInstance on ResponseInstance {
  createdAt
  status
  received
  form {
    data {
      id
    }
  }
  answers {
    data {
      ...Answer
    }
  }
  member {
    data {
      id
      attributes {
        email
        firstName
        lastName
      }
    }
  }
  user {
    data {
      id
      attributes {
        email
        firstName
        lastName
      }
    }
  }
  company {
    data {
      id
    }
  }
}
    `;
export const FormFragmentDoc = gql`
    fragment Form on Form {
  title
  route
  createdAt
  company {
    data {
      attributes {
        name
      }
    }
  }
  job {
    data {
      id
      attributes {
        contact {
          data {
            id
            attributes {
              firstName
              lastName
              name
              email
            }
          }
        }
        address {
          formattedAddress
        }
        members {
          data {
            attributes {
              member {
                data {
                  attributes {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  questions {
    data {
      id
      attributes {
        ...Question
      }
    }
  }
  responses {
    data {
      id
      attributes {
        ...ResponseInstance
      }
    }
  }
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  title
  description
  createdAt
  startDate
  endDate
  job {
    data {
      id
    }
  }
  members {
    data {
      id
      attributes {
        member {
          data {
            id
            attributes {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export const AssignedMemberFragmentDoc = gql`
    fragment AssignedMember on AssignedMemberEntity {
  id
  attributes {
    member {
      data {
        id
        attributes {
          ...User
        }
      }
    }
    tasks {
      data {
        id
        attributes {
          ...Task
        }
      }
    }
    contact {
      data {
        id
        attributes {
          ...Contact
        }
      }
    }
    jobAddress
    company {
      data {
        id
      }
    }
  }
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on Job {
  title
  desc
  startDate
  endDate
  createdAt
  quantityOnSite
  company {
    data {
      id
      attributes {
        ...Company
      }
    }
  }
  address {
    formattedAddress
    longitude
    latitude
  }
  contact {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
  status {
    data {
      ...JobStatus
    }
  }
  gearList {
    data {
      ...File
    }
  }
  plans {
    data {
      ...File
    }
  }
  forms {
    data {
      id
      attributes {
        ...Form
      }
    }
  }
  quotes {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
  invoices {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
  members(pagination: {limit: -1}) {
    data {
      ...AssignedMember
    }
  }
  tasks {
    data {
      id
      attributes {
        ...Task
      }
    }
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
  img {
    data {
      attributes {
        alternativeText
        url
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
  title
}
    `;
export const RegistrationRequestFragmentDoc = gql`
    fragment RegistrationRequest on RegistrationRequest {
  email
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subTitle
  numberValue
  description
  linkTitle
  linkUrl
  list
  icon
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  subTitle
  title
  img {
    data {
      id
      attributes {
        url
        alternativeText
      }
    }
  }
  description
  backgroundImg {
    data {
      attributes {
        alternativeText
        url
      }
    }
  }
  links {
    id
    url
    img {
      data {
        attributes {
          url
          alternativeText
        }
      }
    }
    icon
    title
  }
  backgroundImg {
    data {
      attributes {
        alternativeText
        url
      }
    }
  }
  cards {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  changePassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const CreateAssignedMemberDocument = gql`
    mutation createAssignedMember($data: AssignedMemberInput!) {
  createAssignedMember(data: $data) {
    data {
      ...AssignedMember
    }
  }
}
    ${AssignedMemberFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${TaskFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export type CreateAssignedMemberMutationFn = Apollo.MutationFunction<CreateAssignedMemberMutation, CreateAssignedMemberMutationVariables>;
export function useCreateAssignedMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignedMemberMutation, CreateAssignedMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignedMemberMutation, CreateAssignedMemberMutationVariables>(CreateAssignedMemberDocument, options);
      }
export type CreateAssignedMemberMutationHookResult = ReturnType<typeof useCreateAssignedMemberMutation>;
export type CreateAssignedMemberMutationResult = Apollo.MutationResult<CreateAssignedMemberMutation>;
export const CreateBrandingThemeDocument = gql`
    mutation createBrandingTheme($data: BrandingThemeInput!) {
  createBrandingTheme(data: $data) {
    data {
      ...BrandingTheme
    }
  }
}
    ${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export type CreateBrandingThemeMutationFn = Apollo.MutationFunction<CreateBrandingThemeMutation, CreateBrandingThemeMutationVariables>;
export function useCreateBrandingThemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandingThemeMutation, CreateBrandingThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandingThemeMutation, CreateBrandingThemeMutationVariables>(CreateBrandingThemeDocument, options);
      }
export type CreateBrandingThemeMutationHookResult = ReturnType<typeof useCreateBrandingThemeMutation>;
export type CreateBrandingThemeMutationResult = Apollo.MutationResult<CreateBrandingThemeMutation>;
export const CreateCompanyDocument = gql`
    mutation createCompany($data: CompanyInput!) {
  createCompany(data: $data) {
    data {
      id
      attributes {
        ...Company
      }
    }
  }
}
    ${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export const CreateContactDocument = gql`
    mutation createContact($input: ContactInput!) {
  createContact(data: $input) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
}
    ${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export const CreateFormDocument = gql`
    mutation createForm($data: FormInput!, $questions: [QuestionInput]) {
  createForm(data: $data, questions: $questions) {
    data {
      id
      attributes {
        title
        company {
          data {
            attributes {
              ...Company
            }
          }
        }
        job {
          data {
            attributes {
              ...Job
            }
          }
        }
        questions {
          data {
            attributes {
              ...Question
            }
          }
        }
      }
    }
  }
}
    ${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${JobFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export const CreateFormEmailDocument = gql`
    mutation createFormEmail($data: FormEmailInput!) {
  createFormEmail(data: $data) {
    data {
      id
      attributes {
        to
        info
      }
    }
  }
}
    `;
export type CreateFormEmailMutationFn = Apollo.MutationFunction<CreateFormEmailMutation, CreateFormEmailMutationVariables>;
export function useCreateFormEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormEmailMutation, CreateFormEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormEmailMutation, CreateFormEmailMutationVariables>(CreateFormEmailDocument, options);
      }
export type CreateFormEmailMutationHookResult = ReturnType<typeof useCreateFormEmailMutation>;
export type CreateFormEmailMutationResult = Apollo.MutationResult<CreateFormEmailMutation>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($input: InvoiceInput!) {
  createInvoice(data: $input) {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FileFragmentDoc}`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export const CreateInvoiceEmailDocument = gql`
    mutation createInvoiceEmail($data: InvoiceEmailInput!) {
  createInvoiceEmail(data: $data) {
    data {
      attributes {
        to
        info
        items
      }
    }
  }
}
    `;
export type CreateInvoiceEmailMutationFn = Apollo.MutationFunction<CreateInvoiceEmailMutation, CreateInvoiceEmailMutationVariables>;
export function useCreateInvoiceEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceEmailMutation, CreateInvoiceEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceEmailMutation, CreateInvoiceEmailMutationVariables>(CreateInvoiceEmailDocument, options);
      }
export type CreateInvoiceEmailMutationHookResult = ReturnType<typeof useCreateInvoiceEmailMutation>;
export type CreateInvoiceEmailMutationResult = Apollo.MutationResult<CreateInvoiceEmailMutation>;
export const CreateInvoiceItemDocument = gql`
    mutation createInvoiceItem($data: InvoiceItemInput!) {
  createInvoiceItem(data: $data) {
    data {
      ...InvoiceItem
    }
  }
}
    ${InvoiceItemFragmentDoc}`;
export type CreateInvoiceItemMutationFn = Apollo.MutationFunction<CreateInvoiceItemMutation, CreateInvoiceItemMutationVariables>;
export function useCreateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceItemMutation, CreateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceItemMutation, CreateInvoiceItemMutationVariables>(CreateInvoiceItemDocument, options);
      }
export type CreateInvoiceItemMutationHookResult = ReturnType<typeof useCreateInvoiceItemMutation>;
export type CreateInvoiceItemMutationResult = Apollo.MutationResult<CreateInvoiceItemMutation>;
export const CreateJobDocument = gql`
    mutation createJob($data: JobInput!) {
  createJob(data: $data) {
    data {
      id
      attributes {
        ...Job
      }
    }
  }
}
    ${JobFragmentDoc}
${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export const CreateMemberDocument = gql`
    mutation createMember($input: UsersPermissionsUserInput!, $role: RoleType!) {
  createMember(input: $input, role: $role) {
    ...User
  }
}
    ${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}`;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;
export function useCreateMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, options);
      }
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export const CreateQuestionDocument = gql`
    mutation createQuestion($data: QuestionInput!) {
  createQuestion(data: $data) {
    data {
      id
      attributes {
        ...Question
      }
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export const CreateQuoteDocument = gql`
    mutation createQuote($input: QuoteInput!) {
  createQuote(data: $input) {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
}
    ${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}`;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export const CreateQuoteEmailDocument = gql`
    mutation createQuoteEmail($data: QuoteEmailInput!) {
  createQuoteEmail(data: $data) {
    data {
      attributes {
        to
        info
        items
      }
    }
  }
}
    `;
export type CreateQuoteEmailMutationFn = Apollo.MutationFunction<CreateQuoteEmailMutation, CreateQuoteEmailMutationVariables>;
export function useCreateQuoteEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteEmailMutation, CreateQuoteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteEmailMutation, CreateQuoteEmailMutationVariables>(CreateQuoteEmailDocument, options);
      }
export type CreateQuoteEmailMutationHookResult = ReturnType<typeof useCreateQuoteEmailMutation>;
export type CreateQuoteEmailMutationResult = Apollo.MutationResult<CreateQuoteEmailMutation>;
export const CreateQuoteItemDocument = gql`
    mutation createQuoteItem($data: QuoteItemInput!) {
  createQuoteItem(data: $data) {
    data {
      ...QuoteItem
    }
  }
}
    ${QuoteItemFragmentDoc}
${ServiceFragmentDoc}`;
export type CreateQuoteItemMutationFn = Apollo.MutationFunction<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>;
export function useCreateQuoteItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>(CreateQuoteItemDocument, options);
      }
export type CreateQuoteItemMutationHookResult = ReturnType<typeof useCreateQuoteItemMutation>;
export type CreateQuoteItemMutationResult = Apollo.MutationResult<CreateQuoteItemMutation>;
export const CreateRegistrationRequestDocument = gql`
    mutation createRegistrationRequest($data: RegistrationRequestInput!) {
  createRegistrationRequest(data: $data) {
    data {
      id
      attributes {
        ...RegistrationRequest
      }
    }
  }
}
    ${RegistrationRequestFragmentDoc}`;
export type CreateRegistrationRequestMutationFn = Apollo.MutationFunction<CreateRegistrationRequestMutation, CreateRegistrationRequestMutationVariables>;
export function useCreateRegistrationRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegistrationRequestMutation, CreateRegistrationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegistrationRequestMutation, CreateRegistrationRequestMutationVariables>(CreateRegistrationRequestDocument, options);
      }
export type CreateRegistrationRequestMutationHookResult = ReturnType<typeof useCreateRegistrationRequestMutation>;
export type CreateRegistrationRequestMutationResult = Apollo.MutationResult<CreateRegistrationRequestMutation>;
export const CreateResponseInstanceDocument = gql`
    mutation createResponseInstance($data: ResponseInstanceInput!) {
  createResponseInstance(data: $data) {
    data {
      id
      attributes {
        ...ResponseInstance
      }
    }
  }
}
    ${ResponseInstanceFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}`;
export type CreateResponseInstanceMutationFn = Apollo.MutationFunction<CreateResponseInstanceMutation, CreateResponseInstanceMutationVariables>;
export function useCreateResponseInstanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateResponseInstanceMutation, CreateResponseInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResponseInstanceMutation, CreateResponseInstanceMutationVariables>(CreateResponseInstanceDocument, options);
      }
export type CreateResponseInstanceMutationHookResult = ReturnType<typeof useCreateResponseInstanceMutation>;
export type CreateResponseInstanceMutationResult = Apollo.MutationResult<CreateResponseInstanceMutation>;
export const CreateServiceDocument = gql`
    mutation createService($data: ServiceInput!) {
  createService(data: $data) {
    data {
      id
      attributes {
        ...Service
      }
    }
  }
}
    ${ServiceFragmentDoc}`;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export const CreateTaskDocument = gql`
    mutation createTask($data: TaskInput!) {
  createTask(data: $data) {
    data {
      id
      attributes {
        ...Task
      }
    }
  }
}
    ${TaskFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export const DeleteAssignedMemberDocument = gql`
    mutation deleteAssignedMember($id: ID!) {
  deleteAssignedMember(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteAssignedMemberMutationFn = Apollo.MutationFunction<DeleteAssignedMemberMutation, DeleteAssignedMemberMutationVariables>;
export function useDeleteAssignedMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssignedMemberMutation, DeleteAssignedMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssignedMemberMutation, DeleteAssignedMemberMutationVariables>(DeleteAssignedMemberDocument, options);
      }
export type DeleteAssignedMemberMutationHookResult = ReturnType<typeof useDeleteAssignedMemberMutation>;
export type DeleteAssignedMemberMutationResult = Apollo.MutationResult<DeleteAssignedMemberMutation>;
export const DeleteContactDocument = gql`
    mutation deleteContact($id: ID!) {
  deleteContact(id: $id) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
}
    ${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export const DeleteFormDocument = gql`
    mutation deleteForm($id: ID!) {
  deleteForm(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export const DeleteInvoiceDocument = gql`
    mutation deleteInvoice($id: ID!) {
  deleteInvoice(id: $id) {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FileFragmentDoc}`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export const DeleteInvoiceItemDocument = gql`
    mutation deleteInvoiceItem($id: ID!) {
  deleteInvoiceItem(id: $id) {
    data {
      ...InvoiceItem
    }
  }
}
    ${InvoiceItemFragmentDoc}`;
export type DeleteInvoiceItemMutationFn = Apollo.MutationFunction<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;
export function useDeleteInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>(DeleteInvoiceItemDocument, options);
      }
export type DeleteInvoiceItemMutationHookResult = ReturnType<typeof useDeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationResult = Apollo.MutationResult<DeleteInvoiceItemMutation>;
export const DeleteInvoiceSyncDocument = gql`
    mutation deleteInvoiceSync($id: ID!) {
  deleteInvoiceSync(id: $id) {
    success
  }
}
    `;
export type DeleteInvoiceSyncMutationFn = Apollo.MutationFunction<DeleteInvoiceSyncMutation, DeleteInvoiceSyncMutationVariables>;
export function useDeleteInvoiceSyncMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceSyncMutation, DeleteInvoiceSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceSyncMutation, DeleteInvoiceSyncMutationVariables>(DeleteInvoiceSyncDocument, options);
      }
export type DeleteInvoiceSyncMutationHookResult = ReturnType<typeof useDeleteInvoiceSyncMutation>;
export type DeleteInvoiceSyncMutationResult = Apollo.MutationResult<DeleteInvoiceSyncMutation>;
export const DeleteJobDocument = gql`
    mutation deleteJob($id: ID!) {
  deleteJob(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;
export function useDeleteJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, options);
      }
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export const DeleteQuoteDocument = gql`
    mutation deleteQuote($id: ID!) {
  deleteQuote(id: $id) {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
}
    ${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}`;
export type DeleteQuoteMutationFn = Apollo.MutationFunction<DeleteQuoteMutation, DeleteQuoteMutationVariables>;
export function useDeleteQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteMutation, DeleteQuoteMutationVariables>(DeleteQuoteDocument, options);
      }
export type DeleteQuoteMutationHookResult = ReturnType<typeof useDeleteQuoteMutation>;
export type DeleteQuoteMutationResult = Apollo.MutationResult<DeleteQuoteMutation>;
export const DeleteQuoteItemDocument = gql`
    mutation deleteQuoteItem($id: ID!) {
  deleteQuoteItem(id: $id) {
    data {
      ...QuoteItem
    }
  }
}
    ${QuoteItemFragmentDoc}
${ServiceFragmentDoc}`;
export type DeleteQuoteItemMutationFn = Apollo.MutationFunction<DeleteQuoteItemMutation, DeleteQuoteItemMutationVariables>;
export function useDeleteQuoteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteItemMutation, DeleteQuoteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteItemMutation, DeleteQuoteItemMutationVariables>(DeleteQuoteItemDocument, options);
      }
export type DeleteQuoteItemMutationHookResult = ReturnType<typeof useDeleteQuoteItemMutation>;
export type DeleteQuoteItemMutationResult = Apollo.MutationResult<DeleteQuoteItemMutation>;
export const DeleteQuoteSyncDocument = gql`
    mutation deleteQuoteSync($id: ID!) {
  deleteQuoteSync(id: $id) {
    success
  }
}
    `;
export type DeleteQuoteSyncMutationFn = Apollo.MutationFunction<DeleteQuoteSyncMutation, DeleteQuoteSyncMutationVariables>;
export function useDeleteQuoteSyncMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteSyncMutation, DeleteQuoteSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteSyncMutation, DeleteQuoteSyncMutationVariables>(DeleteQuoteSyncDocument, options);
      }
export type DeleteQuoteSyncMutationHookResult = ReturnType<typeof useDeleteQuoteSyncMutation>;
export type DeleteQuoteSyncMutationResult = Apollo.MutationResult<DeleteQuoteSyncMutation>;
export const DeleteResponseInstanceDocument = gql`
    mutation deleteResponseInstance($id: ID!) {
  deleteResponseInstance(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteResponseInstanceMutationFn = Apollo.MutationFunction<DeleteResponseInstanceMutation, DeleteResponseInstanceMutationVariables>;
export function useDeleteResponseInstanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResponseInstanceMutation, DeleteResponseInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResponseInstanceMutation, DeleteResponseInstanceMutationVariables>(DeleteResponseInstanceDocument, options);
      }
export type DeleteResponseInstanceMutationHookResult = ReturnType<typeof useDeleteResponseInstanceMutation>;
export type DeleteResponseInstanceMutationResult = Apollo.MutationResult<DeleteResponseInstanceMutation>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUsersPermissionsUser(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      username
      confirmed
      role {
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      role {
        id
        name
      }
      email
      username
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const SendFormDocument = gql`
    mutation sendForm($formId: ID!, $userIds: [ID]) {
  sendForm(formId: $formId, userIds: $userIds) {
    result
    message
  }
}
    `;
export type SendFormMutationFn = Apollo.MutationFunction<SendFormMutation, SendFormMutationVariables>;
export function useSendFormMutation(baseOptions?: Apollo.MutationHookOptions<SendFormMutation, SendFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFormMutation, SendFormMutationVariables>(SendFormDocument, options);
      }
export type SendFormMutationHookResult = ReturnType<typeof useSendFormMutation>;
export type SendFormMutationResult = Apollo.MutationResult<SendFormMutation>;
export const SwitchQuoteToInvoiceDocument = gql`
    mutation switchQuoteToInvoice($quoteId: String!) {
  switchQuoteToInvoice(quoteId: $quoteId) {
    success
    message
  }
}
    `;
export type SwitchQuoteToInvoiceMutationFn = Apollo.MutationFunction<SwitchQuoteToInvoiceMutation, SwitchQuoteToInvoiceMutationVariables>;
export function useSwitchQuoteToInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SwitchQuoteToInvoiceMutation, SwitchQuoteToInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchQuoteToInvoiceMutation, SwitchQuoteToInvoiceMutationVariables>(SwitchQuoteToInvoiceDocument, options);
      }
export type SwitchQuoteToInvoiceMutationHookResult = ReturnType<typeof useSwitchQuoteToInvoiceMutation>;
export type SwitchQuoteToInvoiceMutationResult = Apollo.MutationResult<SwitchQuoteToInvoiceMutation>;
export const SyncWithXeroDocument = gql`
    mutation syncWithXero {
  syncWithXero {
    success
    message
  }
}
    `;
export type SyncWithXeroMutationFn = Apollo.MutationFunction<SyncWithXeroMutation, SyncWithXeroMutationVariables>;
export function useSyncWithXeroMutation(baseOptions?: Apollo.MutationHookOptions<SyncWithXeroMutation, SyncWithXeroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncWithXeroMutation, SyncWithXeroMutationVariables>(SyncWithXeroDocument, options);
      }
export type SyncWithXeroMutationHookResult = ReturnType<typeof useSyncWithXeroMutation>;
export type SyncWithXeroMutationResult = Apollo.MutationResult<SyncWithXeroMutation>;
export const UpdateAssignedMemberDocument = gql`
    mutation updateAssignedMember($id: ID!, $input: AssignedMemberInput!) {
  updateAssignedMember(id: $id, data: $input) {
    data {
      ...AssignedMember
    }
  }
}
    ${AssignedMemberFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${TaskFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export type UpdateAssignedMemberMutationFn = Apollo.MutationFunction<UpdateAssignedMemberMutation, UpdateAssignedMemberMutationVariables>;
export function useUpdateAssignedMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignedMemberMutation, UpdateAssignedMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignedMemberMutation, UpdateAssignedMemberMutationVariables>(UpdateAssignedMemberDocument, options);
      }
export type UpdateAssignedMemberMutationHookResult = ReturnType<typeof useUpdateAssignedMemberMutation>;
export type UpdateAssignedMemberMutationResult = Apollo.MutationResult<UpdateAssignedMemberMutation>;
export const UpdateBrandingThemeDocument = gql`
    mutation updateBrandingTheme($id: ID!, $data: BrandingThemeInput!) {
  updateBrandingTheme(id: $id, data: $data) {
    data {
      ...BrandingTheme
    }
  }
}
    ${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export type UpdateBrandingThemeMutationFn = Apollo.MutationFunction<UpdateBrandingThemeMutation, UpdateBrandingThemeMutationVariables>;
export function useUpdateBrandingThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandingThemeMutation, UpdateBrandingThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandingThemeMutation, UpdateBrandingThemeMutationVariables>(UpdateBrandingThemeDocument, options);
      }
export type UpdateBrandingThemeMutationHookResult = ReturnType<typeof useUpdateBrandingThemeMutation>;
export type UpdateBrandingThemeMutationResult = Apollo.MutationResult<UpdateBrandingThemeMutation>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: ContactInput!) {
  updateContact(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
}
    ${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export const UpdateFormDocument = gql`
    mutation updateForm($id: ID!, $data: FormInput!, $questions: [FormQuestion]) {
  updateForm(id: $id, data: $data, questions: $questions) {
    data {
      id
      attributes {
        ...Form
      }
    }
  }
}
    ${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}
${ResponseInstanceFragmentDoc}`;
export type UpdateFormMutationFn = Apollo.MutationFunction<UpdateFormMutation, UpdateFormMutationVariables>;
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormMutation, UpdateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UpdateFormDocument, options);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type UpdateFormMutationResult = Apollo.MutationResult<UpdateFormMutation>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($id: ID!, $input: InvoiceInput!) {
  updateInvoice(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FileFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export const UpdateJobDocument = gql`
    mutation updateJob($id: ID!, $input: JobInput!) {
  updateJob(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Job
      }
    }
  }
}
    ${JobFragmentDoc}
${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export const UpdateJobStatusDocument = gql`
    mutation updateJobStatus($data: JobInput!, $id: ID!) {
  updateJob(data: $data, id: $id) {
    data {
      id
      attributes {
        status {
          data {
            attributes {
              title
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateJobStatusMutationFn = Apollo.MutationFunction<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>;
export function useUpdateJobStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobStatusMutation, UpdateJobStatusMutationVariables>(UpdateJobStatusDocument, options);
      }
export type UpdateJobStatusMutationHookResult = ReturnType<typeof useUpdateJobStatusMutation>;
export type UpdateJobStatusMutationResult = Apollo.MutationResult<UpdateJobStatusMutation>;
export const UpdateMemberDocument = gql`
    mutation updateMember($id: ID!, $input: UsersPermissionsUserInput, $role: RoleType) {
  updateMember(id: $id, input: $input, role: $role) {
    ...User
  }
}
    ${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($input: PushNotificationInput!, $id: ID!) {
  updatePushNotification(data: $input, id: $id) {
    data {
      attributes {
        delivered
      }
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: CompanyInput!) {
  updateOrganization(input: $input) {
    data {
      id
      attributes {
        ...Company
      }
    }
  }
}
    ${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export const UpdateQuestionDocument = gql`
    mutation updateQuestion($id: ID!, $data: QuestionInput!) {
  updateQuestion(id: $id, data: $data) {
    data {
      id
      attributes {
        ...Question
      }
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export const UpdateQuoteDocument = gql`
    mutation updateQuote($id: ID!, $input: QuoteInput!) {
  updateQuote(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
}
    ${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}`;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export function useUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, options);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = Apollo.MutationResult<UpdateQuoteMutation>;
export const UpdateQuoteItemDocument = gql`
    mutation updateQuoteItem($id: ID!, $input: QuoteItemInput!) {
  updateQuoteItem(id: $id, data: $input) {
    data {
      ...QuoteItem
    }
  }
}
    ${QuoteItemFragmentDoc}
${ServiceFragmentDoc}`;
export type UpdateQuoteItemMutationFn = Apollo.MutationFunction<UpdateQuoteItemMutation, UpdateQuoteItemMutationVariables>;
export function useUpdateQuoteItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteItemMutation, UpdateQuoteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteItemMutation, UpdateQuoteItemMutationVariables>(UpdateQuoteItemDocument, options);
      }
export type UpdateQuoteItemMutationHookResult = ReturnType<typeof useUpdateQuoteItemMutation>;
export type UpdateQuoteItemMutationResult = Apollo.MutationResult<UpdateQuoteItemMutation>;
export const UpdateResponseInstanceDocument = gql`
    mutation updateResponseInstance($data: ResponseInstanceInput!, $id: ID!, $answers: [AnswerInput]) {
  updateResponseInstance(data: $data, id: $id, answers: $answers) {
    data {
      id
      attributes {
        ...ResponseInstance
      }
    }
  }
}
    ${ResponseInstanceFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}`;
export type UpdateResponseInstanceMutationFn = Apollo.MutationFunction<UpdateResponseInstanceMutation, UpdateResponseInstanceMutationVariables>;
export function useUpdateResponseInstanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResponseInstanceMutation, UpdateResponseInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResponseInstanceMutation, UpdateResponseInstanceMutationVariables>(UpdateResponseInstanceDocument, options);
      }
export type UpdateResponseInstanceMutationHookResult = ReturnType<typeof useUpdateResponseInstanceMutation>;
export type UpdateResponseInstanceMutationResult = Apollo.MutationResult<UpdateResponseInstanceMutation>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $input: TaskInput!) {
  updateTask(id: $id, data: $input) {
    data {
      id
      attributes {
        ...Task
      }
    }
  }
}
    ${TaskFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UsersPermissionsUserInput!) {
  updateUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UploadDocument = gql`
    mutation upload($file: Upload!) {
  upload(file: $file) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type UploadMutationFn = Apollo.MutationFunction<UploadMutation, UploadMutationVariables>;
export function useUploadMutation(baseOptions?: Apollo.MutationHookOptions<UploadMutation, UploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMutation, UploadMutationVariables>(UploadDocument, options);
      }
export type UploadMutationHookResult = ReturnType<typeof useUploadMutation>;
export type UploadMutationResult = Apollo.MutationResult<UploadMutation>;
export const CheckResponseDocument = gql`
    query CheckResponse($formId: ID!, $userId: ID!) {
  responseInstances(
    filters: {form: {id: {eq: $formId}}, user: {id: {eq: $userId}}}
  ) {
    data {
      id
    }
  }
}
    `;
export function useCheckResponseQuery(baseOptions: Apollo.QueryHookOptions<CheckResponseQuery, CheckResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckResponseQuery, CheckResponseQueryVariables>(CheckResponseDocument, options);
      }
export function useCheckResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckResponseQuery, CheckResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckResponseQuery, CheckResponseQueryVariables>(CheckResponseDocument, options);
        }
export type CheckResponseQueryHookResult = ReturnType<typeof useCheckResponseQuery>;
export type CheckResponseLazyQueryHookResult = ReturnType<typeof useCheckResponseLazyQuery>;
export type CheckResponseQueryResult = Apollo.QueryResult<CheckResponseQuery, CheckResponseQueryVariables>;
export const AssignedMemberDocument = gql`
    query assignedMember($id: ID!) {
  assignedMember(id: $id) {
    data {
      ...AssignedMember
    }
  }
}
    ${AssignedMemberFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${TaskFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export function useAssignedMemberQuery(baseOptions: Apollo.QueryHookOptions<AssignedMemberQuery, AssignedMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignedMemberQuery, AssignedMemberQueryVariables>(AssignedMemberDocument, options);
      }
export function useAssignedMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignedMemberQuery, AssignedMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignedMemberQuery, AssignedMemberQueryVariables>(AssignedMemberDocument, options);
        }
export type AssignedMemberQueryHookResult = ReturnType<typeof useAssignedMemberQuery>;
export type AssignedMemberLazyQueryHookResult = ReturnType<typeof useAssignedMemberLazyQuery>;
export type AssignedMemberQueryResult = Apollo.QueryResult<AssignedMemberQuery, AssignedMemberQueryVariables>;
export const AssignedMembersDocument = gql`
    query assignedMembers($filters: AssignedMemberFiltersInput, $sort: [String]) {
  assignedMembers(filters: $filters, sort: $sort, pagination: {limit: -1}) {
    data {
      ...AssignedMember
    }
  }
}
    ${AssignedMemberFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${TaskFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export function useAssignedMembersQuery(baseOptions?: Apollo.QueryHookOptions<AssignedMembersQuery, AssignedMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignedMembersQuery, AssignedMembersQueryVariables>(AssignedMembersDocument, options);
      }
export function useAssignedMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignedMembersQuery, AssignedMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignedMembersQuery, AssignedMembersQueryVariables>(AssignedMembersDocument, options);
        }
export type AssignedMembersQueryHookResult = ReturnType<typeof useAssignedMembersQuery>;
export type AssignedMembersLazyQueryHookResult = ReturnType<typeof useAssignedMembersLazyQuery>;
export type AssignedMembersQueryResult = Apollo.QueryResult<AssignedMembersQuery, AssignedMembersQueryVariables>;
export const BrandingThemesDocument = gql`
    query brandingThemes($companyId: ID!) {
  brandingThemes(filters: {company: {id: {eq: $companyId}}}) {
    data {
      ...BrandingTheme
    }
  }
}
    ${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export function useBrandingThemesQuery(baseOptions: Apollo.QueryHookOptions<BrandingThemesQuery, BrandingThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandingThemesQuery, BrandingThemesQueryVariables>(BrandingThemesDocument, options);
      }
export function useBrandingThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandingThemesQuery, BrandingThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandingThemesQuery, BrandingThemesQueryVariables>(BrandingThemesDocument, options);
        }
export type BrandingThemesQueryHookResult = ReturnType<typeof useBrandingThemesQuery>;
export type BrandingThemesLazyQueryHookResult = ReturnType<typeof useBrandingThemesLazyQuery>;
export type BrandingThemesQueryResult = Apollo.QueryResult<BrandingThemesQuery, BrandingThemesQueryVariables>;
export const CheckBlockedUserDocument = gql`
    query checkBlockedUser($email: String!) {
  checkBlockedUser(email: $email) {
    result
  }
}
    `;
export function useCheckBlockedUserQuery(baseOptions: Apollo.QueryHookOptions<CheckBlockedUserQuery, CheckBlockedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckBlockedUserQuery, CheckBlockedUserQueryVariables>(CheckBlockedUserDocument, options);
      }
export function useCheckBlockedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckBlockedUserQuery, CheckBlockedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckBlockedUserQuery, CheckBlockedUserQueryVariables>(CheckBlockedUserDocument, options);
        }
export type CheckBlockedUserQueryHookResult = ReturnType<typeof useCheckBlockedUserQuery>;
export type CheckBlockedUserLazyQueryHookResult = ReturnType<typeof useCheckBlockedUserLazyQuery>;
export type CheckBlockedUserQueryResult = Apollo.QueryResult<CheckBlockedUserQuery, CheckBlockedUserQueryVariables>;
export const ContactDocument = gql`
    query contact($id: ID!) {
  contact(id: $id) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
}
    ${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export function useContactQuery(baseOptions: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
      }
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
        }
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ContactsDocument = gql`
    query contacts($filters: ContactFiltersInput, $sort: [String], $pagination: PaginationArg, $type: ContactType) {
  contacts(filters: $filters, sort: $sort, pagination: $pagination, type: $type) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
}
    ${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}`;
export function useContactsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const CurrenciesDocument = gql`
    query currencies($filters: CurrencyFiltersInput, $sort: [String], $pagination: PaginationArg) {
  currencies(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        ...Currency
      }
    }
  }
}
    ${CurrencyFragmentDoc}`;
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const FooterDocument = gql`
    query footer {
  footer {
    data {
      attributes {
        logoLink {
          url
          title
          img {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
        navigationTitle
        navigationLinks {
          ...Link
        }
        contactTitle
        phoneLink {
          url
          title
        }
        location {
          addressLink
          addressText
        }
        bottomSection {
          title
          subTitle
          links {
            ...Link
          }
        }
        socials {
          id
          address
          icon {
            data {
              ...File
            }
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useFooterQuery(baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
      }
export function useFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
        }
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<FooterQuery, FooterQueryVariables>;
export const FormDocument = gql`
    query form($id: ID!, $filters: ResponseInstanceFiltersInput) {
  form(id: $id) {
    data {
      id
      attributes {
        title
        route
        createdAt
        company {
          data {
            attributes {
              name
            }
          }
        }
        job {
          data {
            id
            attributes {
              contact {
                data {
                  id
                  attributes {
                    firstName
                    lastName
                    name
                    email
                  }
                }
              }
              address {
                formattedAddress
              }
              members {
                data {
                  attributes {
                    member {
                      data {
                        attributes {
                          firstName
                          lastName
                          username
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        questions {
          data {
            id
            attributes {
              ...Question
            }
          }
        }
        responses(filters: $filters) {
          data {
            id
            attributes {
              ...ResponseInstance
            }
          }
        }
      }
    }
  }
}
    ${QuestionFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}
${ResponseInstanceFragmentDoc}`;
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const FormsDocument = gql`
    query forms($filters: FormFiltersInput, $sort: [String], $pagination: PaginationArg) {
  forms(filters: $filters, sort: $sort, pagination: $pagination) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        ...Form
      }
    }
  }
}
    ${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}
${ResponseInstanceFragmentDoc}`;
export function useFormsQuery(baseOptions?: Apollo.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsQueryResult = Apollo.QueryResult<FormsQuery, FormsQueryVariables>;
export const GetXeroAvailableOrganizationsDocument = gql`
    query getXeroAvailableOrganizations {
  getXeroAvailableOrganizations {
    id
    name
    type
    createdAt
  }
}
    `;
export function useGetXeroAvailableOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetXeroAvailableOrganizationsQuery, GetXeroAvailableOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetXeroAvailableOrganizationsQuery, GetXeroAvailableOrganizationsQueryVariables>(GetXeroAvailableOrganizationsDocument, options);
      }
export function useGetXeroAvailableOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetXeroAvailableOrganizationsQuery, GetXeroAvailableOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetXeroAvailableOrganizationsQuery, GetXeroAvailableOrganizationsQueryVariables>(GetXeroAvailableOrganizationsDocument, options);
        }
export type GetXeroAvailableOrganizationsQueryHookResult = ReturnType<typeof useGetXeroAvailableOrganizationsQuery>;
export type GetXeroAvailableOrganizationsLazyQueryHookResult = ReturnType<typeof useGetXeroAvailableOrganizationsLazyQuery>;
export type GetXeroAvailableOrganizationsQueryResult = Apollo.QueryResult<GetXeroAvailableOrganizationsQuery, GetXeroAvailableOrganizationsQueryVariables>;
export const GlobalSearchDataDocument = gql`
    query globalSearchData($searchTerm: String!) {
  jobs(
    filters: {or: [{contact: {or: [{firstName: {containsi: $searchTerm}}, {lastName: {containsi: $searchTerm}}, {name: {containsi: $searchTerm}}]}}, {address: {formattedAddress: {containsi: $searchTerm}}}]}
  ) {
    data {
      id
      attributes {
        ...Job
      }
    }
  }
  quotes(
    filters: {or: [{contact: {or: [{firstName: {containsi: $searchTerm}}, {lastName: {containsi: $searchTerm}}, {name: {containsi: $searchTerm}}]}}, {quoteNumber: {containsi: $searchTerm}}]}
  ) {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
  invoices(
    filters: {or: [{contact: {or: [{firstName: {containsi: $searchTerm}}, {lastName: {containsi: $searchTerm}}, {name: {containsi: $searchTerm}}]}}, {invoiceNumber: {containsi: $searchTerm}}]}
  ) {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
  contacts(
    filters: {or: [{name: {contains: $searchTerm}}, {firstName: {contains: $searchTerm}}, {lastName: {contains: $searchTerm}}, {email: {contains: $searchTerm}}]}
  ) {
    data {
      id
      attributes {
        ...Contact
      }
    }
  }
  members(
    filters: {or: [{firstName: {containsi: $searchTerm}}, {lastName: {containsi: $searchTerm}}, {email: {containsi: $searchTerm}}, {role: {name: {containsi: $searchTerm}}}, {company: {name: {containsi: $searchTerm}}}]}
  ) {
    data {
      id
      attributes {
        ...User
      }
    }
  }
}
    ${JobFragmentDoc}
${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export function useGlobalSearchDataQuery(baseOptions: Apollo.QueryHookOptions<GlobalSearchDataQuery, GlobalSearchDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalSearchDataQuery, GlobalSearchDataQueryVariables>(GlobalSearchDataDocument, options);
      }
export function useGlobalSearchDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchDataQuery, GlobalSearchDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalSearchDataQuery, GlobalSearchDataQueryVariables>(GlobalSearchDataDocument, options);
        }
export type GlobalSearchDataQueryHookResult = ReturnType<typeof useGlobalSearchDataQuery>;
export type GlobalSearchDataLazyQueryHookResult = ReturnType<typeof useGlobalSearchDataLazyQuery>;
export type GlobalSearchDataQueryResult = Apollo.QueryResult<GlobalSearchDataQuery, GlobalSearchDataQueryVariables>;
export const HeaderDocument = gql`
    query header {
  header {
    data {
      attributes {
        links {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useHeaderQuery(baseOptions?: Apollo.QueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
      }
export function useHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeaderQuery, HeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeaderQuery, HeaderQueryVariables>(HeaderDocument, options);
        }
export type HeaderQueryHookResult = ReturnType<typeof useHeaderQuery>;
export type HeaderLazyQueryHookResult = ReturnType<typeof useHeaderLazyQuery>;
export type HeaderQueryResult = Apollo.QueryResult<HeaderQuery, HeaderQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        key
        heroSection {
          title
          subTitle
          description
          img {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          links {
            ...Link
          }
          backgroundImg {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          cards {
            ...Card
          }
        }
        aboutSection {
          title
          subTitle
          img {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          description
        }
        advantagesSection {
          ...Section
        }
        approachSection {
          description
          title
          subTitle
          img {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
        pricingCard {
          title
          subTitle
          cards {
            ...Card
          }
        }
        chooseSection {
          title
          subTitle
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
        joinSection {
          title
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
          links {
            ...Link
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${CardFragmentDoc}
${SectionFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const IntegrationDocument = gql`
    query integration {
  integration {
    data {
      id
      attributes {
        steps {
          id
          content
          image {
            data {
              ...File
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
      }
export function useIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
        }
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<typeof useIntegrationLazyQuery>;
export type IntegrationQueryResult = Apollo.QueryResult<IntegrationQuery, IntegrationQueryVariables>;
export const InvoiceDocument = gql`
    query invoice($id: ID!) {
  invoice(id: $id) {
    data {
      attributes {
        ...Invoice
      }
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FileFragmentDoc}`;
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoiceItemsDocument = gql`
    query invoiceItems {
  invoiceItems {
    data {
      ...InvoiceItem
    }
  }
}
    ${InvoiceItemFragmentDoc}`;
export function useInvoiceItemsQuery(baseOptions?: Apollo.QueryHookOptions<InvoiceItemsQuery, InvoiceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceItemsQuery, InvoiceItemsQueryVariables>(InvoiceItemsDocument, options);
      }
export function useInvoiceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceItemsQuery, InvoiceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceItemsQuery, InvoiceItemsQueryVariables>(InvoiceItemsDocument, options);
        }
export type InvoiceItemsQueryHookResult = ReturnType<typeof useInvoiceItemsQuery>;
export type InvoiceItemsLazyQueryHookResult = ReturnType<typeof useInvoiceItemsLazyQuery>;
export type InvoiceItemsQueryResult = Apollo.QueryResult<InvoiceItemsQuery, InvoiceItemsQueryVariables>;
export const InvoicesDocument = gql`
    query invoices($filters: InvoiceFiltersInput, $sort: [String], $pagination: PaginationArg) {
  invoices(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        ...Invoice
      }
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FileFragmentDoc}`;
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const JobDocument = gql`
    query job($id: ID!) {
  job(id: $id) {
    data {
      id
      attributes {
        ...Job
      }
    }
  }
}
    ${JobFragmentDoc}
${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export function useJobQuery(baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options);
      }
export function useJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>;
export const JobStatusesDocument = gql`
    query jobStatuses {
  jobStatuses(pagination: {limit: 100}) {
    data {
      ...JobStatus
    }
  }
}
    ${JobStatusFragmentDoc}`;
export function useJobStatusesQuery(baseOptions?: Apollo.QueryHookOptions<JobStatusesQuery, JobStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStatusesQuery, JobStatusesQueryVariables>(JobStatusesDocument, options);
      }
export function useJobStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStatusesQuery, JobStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStatusesQuery, JobStatusesQueryVariables>(JobStatusesDocument, options);
        }
export type JobStatusesQueryHookResult = ReturnType<typeof useJobStatusesQuery>;
export type JobStatusesLazyQueryHookResult = ReturnType<typeof useJobStatusesLazyQuery>;
export type JobStatusesQueryResult = Apollo.QueryResult<JobStatusesQuery, JobStatusesQueryVariables>;
export const JobsDocument = gql`
    query jobs($filters: JobFiltersInput, $sort: [String], $pagination: PaginationArg) {
  jobs(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        ...Job
      }
    }
  }
}
    ${JobFragmentDoc}
${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}
${ContactFragmentDoc}
${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}
${FinancialFragmentDoc}
${SalesFragmentDoc}
${AddressFragmentDoc}
${JobStatusFragmentDoc}
${FormFragmentDoc}
${QuestionFragmentDoc}
${AnswerFragmentDoc}
${ResponseInstanceFragmentDoc}
${AssignedMemberFragmentDoc}
${TaskFragmentDoc}`;
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MembersDocument = gql`
    query members($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  members(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}`;
export function useMembersQuery(baseOptions?: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const NotFoundDocument = gql`
    query notFound {
  notFound {
    data {
      attributes {
        notFound {
          title
          img {
            data {
              attributes {
                url
              }
            }
          }
          description
          links {
            ...Link
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useNotFoundQuery(baseOptions?: Apollo.QueryHookOptions<NotFoundQuery, NotFoundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotFoundQuery, NotFoundQueryVariables>(NotFoundDocument, options);
      }
export function useNotFoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotFoundQuery, NotFoundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotFoundQuery, NotFoundQueryVariables>(NotFoundDocument, options);
        }
export type NotFoundQueryHookResult = ReturnType<typeof useNotFoundQuery>;
export type NotFoundLazyQueryHookResult = ReturnType<typeof useNotFoundLazyQuery>;
export type NotFoundQueryResult = Apollo.QueryResult<NotFoundQuery, NotFoundQueryVariables>;
export const OrganizationDocument = gql`
    query organization {
  organization {
    ...Company
  }
}
    ${CompanyFragmentDoc}
${UserFragmentDoc}
${UsersPermissionsRoleFragmentDoc}
${BrandingThemeFragmentDoc}
${FileFragmentDoc}`;
export function useOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const PricingHeadDocument = gql`
    query pricingHead {
  pricing {
    data {
      attributes {
        description
        subTitle
        title
        image {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function usePricingHeadQuery(baseOptions?: Apollo.QueryHookOptions<PricingHeadQuery, PricingHeadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricingHeadQuery, PricingHeadQueryVariables>(PricingHeadDocument, options);
      }
export function usePricingHeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricingHeadQuery, PricingHeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricingHeadQuery, PricingHeadQueryVariables>(PricingHeadDocument, options);
        }
export type PricingHeadQueryHookResult = ReturnType<typeof usePricingHeadQuery>;
export type PricingHeadLazyQueryHookResult = ReturnType<typeof usePricingHeadLazyQuery>;
export type PricingHeadQueryResult = Apollo.QueryResult<PricingHeadQuery, PricingHeadQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privacyPolicy {
    data {
      attributes {
        privacyPolicy {
          ...Paragraph
        }
        terms {
          ...Paragraph
        }
      }
    }
  }
}
    ${ParagraphFragmentDoc}`;
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const QuoteDocument = gql`
    query Quote($id: ID!) {
  quote(id: $id) {
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
}
    ${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}`;
export function useQuoteQuery(baseOptions: Apollo.QueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
      }
export function useQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
        }
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = Apollo.QueryResult<QuoteQuery, QuoteQueryVariables>;
export const QuotesDocument = gql`
    query quotes($filters: QuoteFiltersInput, $sort: [String], $pagination: PaginationArg) {
  quotes(filters: $filters, sort: $sort, pagination: $pagination) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        ...Quote
      }
    }
  }
}
    ${QuoteFragmentDoc}
${QuoteItemFragmentDoc}
${ServiceFragmentDoc}
${FileFragmentDoc}
${InvoiceFragmentDoc}
${InvoiceItemFragmentDoc}`;
export function useQuotesQuery(baseOptions?: Apollo.QueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
      }
export function useQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
        }
export type QuotesQueryHookResult = ReturnType<typeof useQuotesQuery>;
export type QuotesLazyQueryHookResult = ReturnType<typeof useQuotesLazyQuery>;
export type QuotesQueryResult = Apollo.QueryResult<QuotesQuery, QuotesQueryVariables>;
export const ResponseInstanceDocument = gql`
    query responseInstance($id: ID!) {
  responseInstance(id: $id) {
    data {
      id
    }
  }
}
    `;
export function useResponseInstanceQuery(baseOptions: Apollo.QueryHookOptions<ResponseInstanceQuery, ResponseInstanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseInstanceQuery, ResponseInstanceQueryVariables>(ResponseInstanceDocument, options);
      }
export function useResponseInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseInstanceQuery, ResponseInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseInstanceQuery, ResponseInstanceQueryVariables>(ResponseInstanceDocument, options);
        }
export type ResponseInstanceQueryHookResult = ReturnType<typeof useResponseInstanceQuery>;
export type ResponseInstanceLazyQueryHookResult = ReturnType<typeof useResponseInstanceLazyQuery>;
export type ResponseInstanceQueryResult = Apollo.QueryResult<ResponseInstanceQuery, ResponseInstanceQueryVariables>;
export const ResponseInstancesDocument = gql`
    query responseInstances($filters: ResponseInstanceFiltersInput) {
  responseInstances(filters: $filters) {
    data {
      id
      attributes {
        ...ResponseInstance
      }
    }
  }
}
    ${ResponseInstanceFragmentDoc}
${AnswerFragmentDoc}
${FileFragmentDoc}`;
export function useResponseInstancesQuery(baseOptions?: Apollo.QueryHookOptions<ResponseInstancesQuery, ResponseInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseInstancesQuery, ResponseInstancesQueryVariables>(ResponseInstancesDocument, options);
      }
export function useResponseInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseInstancesQuery, ResponseInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseInstancesQuery, ResponseInstancesQueryVariables>(ResponseInstancesDocument, options);
        }
export type ResponseInstancesQueryHookResult = ReturnType<typeof useResponseInstancesQuery>;
export type ResponseInstancesLazyQueryHookResult = ReturnType<typeof useResponseInstancesLazyQuery>;
export type ResponseInstancesQueryResult = Apollo.QueryResult<ResponseInstancesQuery, ResponseInstancesQueryVariables>;
export const ServicesDocument = gql`
    query services($filters: ServiceFiltersInput) {
  services(filters: $filters) {
    data {
      id
      attributes {
        ...Service
      }
    }
  }
}
    ${ServiceFragmentDoc}`;
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const TasksDocument = gql`
    query tasks($filters: TaskFiltersInput, $sort: [String], $pagination: PaginationArg) {
  tasks(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        ...Task
      }
    }
  }
}
    ${TaskFragmentDoc}`;
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TaxAmountDocument = gql`
    query taxAmount($id: ID!) {
  taxAmount(id: $id) {
    data {
      id
      attributes {
        tax
        percent
        invoices {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useTaxAmountQuery(baseOptions: Apollo.QueryHookOptions<TaxAmountQuery, TaxAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxAmountQuery, TaxAmountQueryVariables>(TaxAmountDocument, options);
      }
export function useTaxAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxAmountQuery, TaxAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxAmountQuery, TaxAmountQueryVariables>(TaxAmountDocument, options);
        }
export type TaxAmountQueryHookResult = ReturnType<typeof useTaxAmountQuery>;
export type TaxAmountLazyQueryHookResult = ReturnType<typeof useTaxAmountLazyQuery>;
export type TaxAmountQueryResult = Apollo.QueryResult<TaxAmountQuery, TaxAmountQueryVariables>;
export const TaxAmountsDocument = gql`
    query taxAmounts {
  taxAmounts {
    data {
      id
      attributes {
        tax
        percent
        invoices {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useTaxAmountsQuery(baseOptions?: Apollo.QueryHookOptions<TaxAmountsQuery, TaxAmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxAmountsQuery, TaxAmountsQueryVariables>(TaxAmountsDocument, options);
      }
export function useTaxAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxAmountsQuery, TaxAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxAmountsQuery, TaxAmountsQueryVariables>(TaxAmountsDocument, options);
        }
export type TaxAmountsQueryHookResult = ReturnType<typeof useTaxAmountsQuery>;
export type TaxAmountsLazyQueryHookResult = ReturnType<typeof useTaxAmountsLazyQuery>;
export type TaxAmountsQueryResult = Apollo.QueryResult<TaxAmountsQuery, TaxAmountsQueryVariables>;
export const UsersPermissionsRolesDocument = gql`
    query usersPermissionsRoles($filters: UsersPermissionsRoleFiltersInput, $sort: [String], $pagination: PaginationArg) {
  usersPermissionsRoles(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...UsersPermissionsRole
    }
  }
}
    ${UsersPermissionsRoleFragmentDoc}`;
export function useUsersPermissionsRolesQuery(baseOptions?: Apollo.QueryHookOptions<UsersPermissionsRolesQuery, UsersPermissionsRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPermissionsRolesQuery, UsersPermissionsRolesQueryVariables>(UsersPermissionsRolesDocument, options);
      }
export function useUsersPermissionsRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPermissionsRolesQuery, UsersPermissionsRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPermissionsRolesQuery, UsersPermissionsRolesQueryVariables>(UsersPermissionsRolesDocument, options);
        }
export type UsersPermissionsRolesQueryHookResult = ReturnType<typeof useUsersPermissionsRolesQuery>;
export type UsersPermissionsRolesLazyQueryHookResult = ReturnType<typeof useUsersPermissionsRolesLazyQuery>;
export type UsersPermissionsRolesQueryResult = Apollo.QueryResult<UsersPermissionsRolesQuery, UsersPermissionsRolesQueryVariables>;