import React, { createContext, useState, useEffect, FC, PropsWithChildren, useContext } from "react";
import { useMeQuery,useUpdateNotificationMutation } from "../../graphql";

interface NotificationContextProps {
  permission: NotificationPermission;
  showNotification: (title: string, options?: NotificationOptions, id?: string) => void;
}

const defaultValue: NotificationContextProps = {
  permission: "default",
  showNotification: () => {},
};

export const NotificationContext = createContext<NotificationContextProps>(defaultValue);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [permission, setPermission] = useState<NotificationPermission>("granted");
  const { data } = useMeQuery();
  const [updateNotification] = useUpdateNotificationMutation();

  useEffect(() => {
    Notification.requestPermission().then((result) => {
      setPermission(result);
    });

    if (data) {
      const undeliveredNotifications = data?.me?.push_notifications?.data.filter(
        (notification: PushNotificationEntity) => !notification?.attributes?.delivered
      );

      undeliveredNotifications?.forEach((notification: PushNotificationEntity) => {
        showNotification(notification?.attributes?.title ?? "", {
          body: notification?.attributes?.body ?? "",
        }, notification.id ?? "");
      });
    }
  }, [data]);

  const showNotification = async (title: string, options?: NotificationOptions, id?: string) => {
    if (permission === "granted") {
      new Notification(title, options);
      if (id) {
        await updateNotification({ variables: { id, input: {
          delivered: true
        } } });
      }
    } else if (permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification(title, options);
          if (id) {
            updateNotification({ variables: { id, input: {
                  delivered: true
            } } });
          }
        }
      });
    }
  };

  return (
    <NotificationContext.Provider value={{ permission, showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);