import React, { createContext, useState } from "react"

interface ContactContextProps {
  children: React.ReactNode
}

export const ContactContext = createContext<{
  contactData: ContactEntity[]
  setContactData: React.Dispatch<React.SetStateAction<ContactEntity[]>>
  filterCounts: Numberify<ContactInput>
  setFiltersCounts: React.Dispatch<React.SetStateAction<Numberify<ContactInput>>>
  contactFilters: ContactFiltersInput
  setContactFilters: React.Dispatch<React.SetStateAction<ContactFiltersInput>>
}>({
  contactData: [],
  setContactData: () => null,
  filterCounts: {},
  setFiltersCounts: () => null,
  contactFilters: {},
  setContactFilters: () => null,
})

export const ContactContextProvider: React.FC<ContactContextProps> = ({ children }) => {
  const [contactFilters, setContactFilters] = useState<ContactFiltersInput>({})
  const [filterCounts, setFiltersCounts] = useState<Numberify<ContactInput>>({})
  const [contactData, setContactData] = useState<ContactEntity[]>([])

  const contextValue = {
    contactData,
    setContactData,
    filterCounts,
    setFiltersCounts,
    contactFilters,
    setContactFilters,
  }

  return <ContactContext.Provider value={contextValue}>{children}</ContactContext.Provider>
}
