import { Col, Row } from "antd"

import imageHero from "./assets/hero.png"
import imageTest from "./assets/test.png"

import { useBreakpoints } from "src/hooks/useBreakpoints"
import "./index.less"

const AuthSection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isXL } = useBreakpoints()

  return (
    <Row className={"auth-section"}>
      <Col span={isXL ? 12 : 24}>{children}</Col>
      {isXL && (
        <Col
          span={12}
          className={"auth-description"}
          style={{ "--auth-background-image-url": `url(${imageHero})` } as React.CSSProperties}
        >
          <div className={"auth-description-wrapper"}>
            <h2 className={"auth-description-title"}>Now you can control everything in one place</h2>
            <p className={"auth-description-text"}>
              Our extensive selection of scaffold solutions, including versatile mobile towers, caters to a wide range
              of construction needs
            </p>
            <div className={"auth-description-image-wrapper"}>
              <img src={imageTest} alt={"image"} className={"auth-description-image"} />
            </div>
          </div>

          <div className={"auth-description-wrapper-overlay"} />
        </Col>
      )}
    </Row>
  )
}

export { AuthSection }
