import { Col, Row } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"

import { ReactComponent as LogoSvg } from "src/icons/logo.svg"

const AuthHeader: FC = () => {
  return (
    <Row align={"middle"} justify={"start"} className={"auth-header-logo"}>
      <Col>
        <Link to={"/"} style={{display: "flex", alignItems: "center"}}>
          <LogoSvg />
        </Link>
      </Col>
    </Row>
  )
}

export { AuthHeader }
