import { Col, Image, Row, Space, Typography } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useFooterQuery } from "src/graphql"
import { VSpace } from "../../../../ui/VerticalSpace"
import "./index.less"

export const DefaultFooter: FC = () => {
  const { data } = useFooterQuery()

  const logoLink = data?.footer?.data?.attributes?.logoLink
  const navigationTitle = data?.footer?.data?.attributes?.navigationTitle
  const navigationLinks = data?.footer?.data?.attributes?.navigationLinks || []
  const contactTitle = data?.footer?.data?.attributes?.contactTitle
  const phoneLink = data?.footer?.data?.attributes?.phoneLink
  const locationLink = data?.footer?.data?.attributes?.location?.addressLink
  const locationText = data?.footer?.data?.attributes?.location?.addressText
  const bottomSection = data?.footer?.data?.attributes?.bottomSection

  return (
    <section className={"footer-container"}>
      <Row justify={"space-between"}>
        <Col>
          <Link style={{ display: "flex", alignItems: "center" }} to={logoLink?.url ?? "/defaultPath"}>
            <img
              src={logoLink?.img?.data?.attributes?.url}
              alt={logoLink?.img?.data?.attributes?.alternativeText ?? "Logo"}
            />
            <span className={"logo-title"}>{logoLink?.title}</span>
          </Link>
        </Col>
        <div className={"nav-contact-container"}>
          <Col className={"nav-container"}>
            <Typography.Text type={"secondary"}>
              <h5 className={"nav-title"}>{navigationTitle}</h5>
              <VSpace>
                {navigationLinks.map(link => (
                  <Link className={"nav-link"} key={link?.id} to={link?.url ?? "/defaultPath"}>
                    {link?.title}
                  </Link>
                ))}
              </VSpace>
            </Typography.Text>
          </Col>

          <Col className={"contact-container"}>
            <VSpace>
              <Typography.Text type={"secondary"}>
                <h5 className={"contact-title"}>{contactTitle}</h5>
                <a className={"contact-link"} href={"tel:" + phoneLink?.url}>
                  {phoneLink?.title}
                </a>
              </Typography.Text>

              <Typography.Link href={locationLink ?? ""} target={"_blank"} style={{ display: "block" }}>
                {locationText}
              </Typography.Link>

              <Space size={16}>
                {data?.footer?.data?.attributes?.socials?.map(item => (
                  <Typography.Link key={item?.id} href={item?.address ?? "#"} target={"_blank"}>
                    <Image
                      src={item?.icon?.data?.attributes?.url ?? ""}
                      alt={item?.icon?.data?.attributes?.name}
                      preview={false}
                    />
                  </Typography.Link>
                ))}
              </Space>
            </VSpace>
          </Col>
        </div>
      </Row>
      <section className={"bottom-container"}>
        <p className={"bottom-title"}>{bottomSection?.title}</p>
        <div className={"link-container"}>
          {bottomSection?.links?.map(link => (
            <Link key={link?.id} className={"links"} to={link?.url || "/"}>
              {link?.title}
            </Link>
          ))}
        </div>
      </section>
    </section>
  )
}
