import { ThemeConfig } from "antd/es/config-provider"

export const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "Circular Std",
  },
  components: {
    Typography: {
      fontSizeHeading1: 32,
      fontSizeHeading2: 20,
    },
    Tag: {
      borderRadiusSM: 16,
      colorBorder: "black",
      defaultBg: "none",
      fontSizeSM: 14,
    },
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
      darkItemBg: "#12212B",
      darkItemSelectedBg: "rgb(255, 255, 255, 0.1)",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
      siderBg: "#12212B",
      triggerHeight: 36,
      triggerColor: "#00519B",
    },
    Tabs: {
      itemSelectedColor: "black",
      lineWidthFocus: 0,
      itemColor: "#8A959E",
      itemActiveColor: "black",
      itemHoverColor: "black",
      inkBarColor: "black",
      fontSize: 11,
      fontWeightStrong: 700,
    },
    Table: {
      colorLinkHover: "black",
      borderColor: "#F8F8F8"
    },
    Radio: {
      buttonColor: "#8A959E",
    },
    Collapse: {
      colorFillAlter: "white",
    },
    Select: {
      zIndexPopupBase: 2100
    }
  },
}

export const mobileTheme: ThemeConfig = {
  inherit: true,
  components: {
    Typography: {
      fontSizeHeading1: 24,
      fontSizeHeading2: 18,
    },
    Tabs: {
      horizontalItemGutter: 24,
    },
  },
}
