import { AvatarProps } from "antd"
import { FC } from "react"
import { getInitials } from "src/utils/getInitials"

import "./index.less"

interface CustomAvatarProps extends AvatarProps {
  src?: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
}

export const Avatar: FC<CustomAvatarProps> = ({ src, firstName, name, lastName, email }) => {
  const contactName = firstName || lastName ? `${firstName} ${lastName}` : name || email || "-"

  return (
    <div className={"contact-avatar"}>
      {src ? <img src={src} alt={"Avatar"} /> : <span>{getInitials(contactName)}</span>}
    </div>
  )
}
