import React, { ReactNode, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { useGlobalSearchDataQuery } from "../graphql"
import { highlightMatchWithSearch } from "../lib/highlightMatchWithSearch"

const labelStyles: any = {
  color: "#8A959E",
  fontWeight: 700,
  fontSize: 11,
  textTransform: "uppercase",
}

const optionStyles: any = {
  color: "#222",
  fontSize: 14,
}

export type OptionType = {
  value: string
  label: string | ReactNode
  key: string
}

export const useGlobalSearchData = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string>("")
  const [_, setSearchParams] = useSearchParams()

  const { data } = useGlobalSearchDataQuery({ variables: { searchTerm: searchValue } })

  const hasField = (field: string) => {
    return field?.toLowerCase()?.includes(searchValue?.toLowerCase())
  }

  const updateRecentSearches = (option: OptionType) => {
    let recentSearches = getRecentSearches()
    recentSearches = [option, ...recentSearches.filter((item: OptionType) => item?.key !== option.key).slice(0, 2)]
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches))
  }

  const getRecentSearches = () => {
    const recentSearches = localStorage.getItem("recentSearches")
    return recentSearches ? JSON.parse(recentSearches) : []
  }

  const searchedOptions = Object.entries(data ?? {})
    ?.filter(item => !!item[1]?.data?.length)
    ?.map(item => ({
      label: <span style={{ ...labelStyles }}>{item[0]}</span>,
      options: item[1]?.data?.map(
        (it: ContactEntity | InvoiceEntity | QuoteEntity | JobEntity | UsersPermissionsUserEntity | any) => {
          const firstName = it?.attributes?.contact?.data?.attributes?.firstName ?? ""
          const lastName = it?.attributes?.contact?.data?.attributes?.lastName ?? ""
          const name = it?.attributes?.contact?.data?.attributes?.name ?? ""
          const memberLastname = it?.attributes?.lastName
          const memberFirstName = it?.attributes?.firstName
          const contactName = !firstName || !!lastName ? `${firstName} ${lastName}` : name
          const contacts = {
            name: it?.attributes?.name,
            firstName: it?.attributes?.firstName,
            lastName: it?.attributes?.lastName,
          }

          const searchedItem = hasField(firstName)
            ? contactName
            : hasField(lastName)
              ? contactName
              : hasField(name)
                ? contactName
                : hasField(contacts?.firstName)
                  ? `${contacts?.firstName} ${contacts?.lastName}`
                  : hasField(contacts?.lastName)
                    ? `${contacts?.firstName} ${contacts?.lastName}`
                    : hasField(contacts?.name)
                      ? `${contacts?.name}`
                      : hasField(it?.attributes?.address)
                        ? `${it?.attributes?.address}`
                        : hasField(it?.attributes?.email)
                          ? `${it?.attributes?.email}`
                          : hasField(it?.attributes?.quoteNumber)
                            ? `${it?.attributes?.quoteNumber}`
                            : hasField(it?.attributes?.invoiceNumber)
                              ? `${it?.attributes?.invoiceNumber}`
                              : hasField(String(it?.attributes?.totalAmount))
                                ? String(it?.attributes?.totalAmount)
                                : hasField(it?.attributes?.status?.title)
                                  ? `${it?.attributes?.status?.title}`
                                  : hasField(it?.attributes?.role?.data?.attributes?.name)
                                    ? `${it?.attributes?.role?.data?.attributes?.name}`
                                    : memberFirstName
                                      ? hasField(memberFirstName)
                                      : memberLastname
                                        ? hasField(memberLastname)
                                        : memberLastname && memberFirstName
                                          ? hasField(`${memberFirstName} ${memberLastname}`)
                                          : ""

          return {
            value: searchedItem,
            key: uuidv4(),
            label: (
              <span
                style={{ ...optionStyles }}
                onClick={() => {
                  navigate(`/${item[0]}?search=${it?.id}`)
                  setSearchValue(contactName)
                }}
              >
                {highlightMatchWithSearch(searchedItem ?? "", searchValue ?? "")}
              </span>
            ),
          }
        }
      ),
    }))

  const recentSearches = {
    label: <span style={{ ...labelStyles }}>{"Recent"}</span>,
    options: getRecentSearches()?.map((it: OptionType) => ({
      key: it?.key,
      value: it?.value,
      label: it?.label,
    })),
  }

  const options = [{ ...recentSearches }, ...searchedOptions]

  const onClearSearch = () => setSearchParams("")

  const onChangeSearch = (event: any) => setSearchValue(event.target.value)

  return { searchValue, options, recentSearches, searchedOptions, updateRecentSearches, onChangeSearch, onClearSearch }
}
