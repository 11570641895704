import { FC, PropsWithChildren } from "react"
import { Space, SpaceProps } from "antd"

export const VSpace: FC<PropsWithChildren<SpaceProps>> = ({ children, ...props }) => (
  <Space
    direction={"vertical"}
    style={{ width: "100%" }}
    {...props}
  >
    {children}
  </Space>
)
