import { BellOutlined, EyeOutlined, LogoutOutlined, MenuOutlined, SearchOutlined } from "@ant-design/icons"
import { Drawer, Dropdown, Popover, Row } from "antd"
import { FC, Fragment, useState } from "react"
import { Link, NavLink } from "react-router-dom"

import { useBreakpoints } from "src/hooks/useBreakpoints"
import { ReactComponent as LogoSvg } from "src/icons/logo.svg"
import { ReactComponent as SearchSvg } from "src/icons/search-icon-md.svg"
import { useApp } from "../../app"
import { GlobalSearchAutocomplete } from "../elements/global-search-autocomplete"
import { NotificationsPopover } from "../elements/notifications"
import { UpdateIntegrationModal } from "../elements/update-integration-modal"
import { Avatar } from "../ui/Avatar"
import "./DashboardHeader.less"
import { SideBarMenu } from "./SideBarMenu"

const DashboardHeader: FC = () => {
  const { isMobile } = useBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)

  const { user } = useApp()

  const handleSignOut = () => {
    localStorage.removeItem("jwt")
    window.location.reload()
  }
  const onClose = () => setIsMenuOpen(false)

  const items = [
    {
      key: "1",
      label: (
        <NavLink to={`/profile/personal-info`} className={"dropdown-btn"}>
          <EyeOutlined style={{ fontSize: 16 }} />
          View Profile
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <Row onClick={handleSignOut} className={"dropdown-btn"}>
          <LogoutOutlined style={{ fontSize: 16 }} />
          <span className={"log-out"}>Log Out</span>
        </Row>
      ),
    },
  ]

  return (
    <Fragment>
      <section className={"dashboard-header"}>
        <article className={"header-input-container"}>
          {!isMobile ? (
            <GlobalSearchAutocomplete />
          ) : (
            <div className={"burger-wrapper"} onClick={() => setIsMenuOpen(true)}>
              <MenuOutlined style={{ color: "#A6ADB4" }} />
            </div>
          )}
        </article>
        {isMobile && (
          <Link to={"/"} style={{ display: "flex", alignItems: "center" }}>
            <LogoSvg />
          </Link>
        )}
        <article className={"header-user-container"}>
          <div className={"header-icon-container"}>
            {isMobile ? (
              <Link to={"/search"} style={{ display: "flex", alignItems: "center" }}>
                <SearchSvg />
              </Link>
            ) : (
              <NotificationsPopover onUpdateXeroIntegration={() => setOpenUpdateModal(true)} />
            )}
          </div>
          <div className={"header-avatar-container"}>
            {isMobile ? (
              <Link to={"/notifications"} style={{ display: "block" }}>
                <BellOutlined style={{ color: "#A6ADB4", fontSize: 20, display: "block" }} />
              </Link>
            ) : (
              <Dropdown className={"user-avatar"} trigger={["click"]} menu={{ items }} placement={"bottomLeft"}>
                <Avatar
                  src={user?.attributes?.avatar?.data?.attributes?.url}
                  firstName={user.attributes?.firstName as string}
                  lastName={user.attributes?.lastName as string}
                  email={user.attributes?.email}
                  size={"large"}
                />
              </Dropdown>
            )}
          </div>
        </article>
        <Drawer
          styles={{
            body: {
              padding: "48px 16px",
              background: "#12212B",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          open={isMenuOpen}
          onClose={onClose}
          placement={"left"}
          width={280}
          closeIcon={null}
        >
          <SideBarMenu onSelect={onClose} />
        </Drawer>
      </section>

      <UpdateIntegrationModal open={openUpdateModal} onCancel={() => setOpenUpdateModal(false)} />
    </Fragment>
  )
}

export { DashboardHeader }
