import React, { createContext, useState } from "react"

interface JobsContextProps {
  children: React.ReactNode
}

export const JobsContext = createContext<{
  jobData: JobEntity[]
  setJobData: React.Dispatch<React.SetStateAction<JobEntity[]>>
  selectedMember: UsersPermissionsUserEntity | undefined
  setSelectedMember: React.Dispatch<React.SetStateAction<UsersPermissionsUserEntity | undefined>>
  isSelectDrawerOpen: boolean
  setIsSelectDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
  jobData: [],
  setJobData: () => null,
  selectedMember: undefined,
  setSelectedMember: () => null,
  isSelectDrawerOpen: false,
  setIsSelectDrawerOpen: () => null,
})

export const JobsContextProvider: React.FC<JobsContextProps> = ({ children }) => {
  const [selectedMember, setSelectedMember] = useState<UsersPermissionsUserEntity | undefined>(undefined)
  const [isSelectDrawerOpen, setIsSelectDrawerOpen] = useState(false)

  const [jobData, setJobData] = useState<JobEntity[]>([])
  const contextValue = {
    jobData,
    setJobData,
    selectedMember,
    setSelectedMember,
    isSelectDrawerOpen,
    setIsSelectDrawerOpen,
  }

  return <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>
}
