import { Button as AntButton, ButtonProps as AntButtonProps } from "antd"
import clsx from "clsx"
import "./index.less"

interface ButtonProps extends AntButtonProps {
  underline?: boolean
}

export const Button: React.FC<ButtonProps> = props => {
  const { underline = false, className, ...otherProps } = props
  return <AntButton className={clsx("scaff-btn", underline && "scaff-btn-underline", className)} {...otherProps} />
}
