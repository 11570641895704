import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Space, Typography } from "antd"
import { FC } from "react"

import { Button } from "../../../ui/Button"
import { VSpace } from "../../../ui/VerticalSpace"
import "./index.less"

const { Title, Paragraph } = Typography
export const UpdateIntegrationMessage: FC<{ onUpdateXeroIntegration: VoidFunction }> = ({
  onUpdateXeroIntegration,
}) => {
  return (
    <Space
      className={"integrate-xero-message-card"}
      size={24}
      align={"start"}
      // style={{ padding: "16px 0", background: "teal" }}
    >
      <ExclamationCircleOutlined style={{ fontSize: 28, background: "#FAAD14", color: "#fff", borderRadius: "50%" }} />
      <VSpace className={"content"}>
        <Title style={{ fontSize: 16 }}>Integration</Title>
        <VSpace size={20}>
          <Paragraph>
            Your Xero integration needs attention! To ensure uninterrupted service, kindly update your access tokens.
          </Paragraph>
          <Button size={"large"} type={"primary"} style={{ width: "100%" }} onClick={onUpdateXeroIntegration}>
            Update
          </Button>
        </VSpace>
      </VSpace>
    </Space>
  )
}
