import React, { createContext, useState } from "react"

interface InvoiceContextProps {
  children: React.ReactNode
}

export const InvoiceContext = createContext<{
  isClearAllButtonClicked: boolean
  setIsClearAllButtonClicked: React.Dispatch<React.SetStateAction<boolean>>
  selectedFiltersCount: number
  setSelectedFiltersCount: React.Dispatch<React.SetStateAction<number>>
  isDrawerSubmitted: boolean
  setIsDrawerSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
  isClearAllButtonClicked: false,
  setIsClearAllButtonClicked: () => null,
  selectedFiltersCount: 0,
  setSelectedFiltersCount: () => null,
  isDrawerSubmitted: false,
  setIsDrawerSubmitted: () => null,
  isDrawerOpen: false,
  setIsDrawerOpen: () => null,
})

export const InvoiceContextProvider: React.FC<InvoiceContextProps> = ({ children }) => {
  const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0)
  const [isClearAllButtonClicked, setIsClearAllButtonClicked] = useState(false)
  const [isDrawerSubmitted, setIsDrawerSubmitted] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const contextValue = {
    isClearAllButtonClicked,
    setIsClearAllButtonClicked,
    selectedFiltersCount,
    setSelectedFiltersCount,
    isDrawerSubmitted,
    setIsDrawerSubmitted,
    isDrawerOpen,
    setIsDrawerOpen,
  }

  return <InvoiceContext.Provider value={contextValue}>{children}</InvoiceContext.Provider>
}
