import { BellOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import { FC } from "react"

import { NotificationsContent } from "../notifications-content"
import "./index.less"

export const NotificationsPopover: FC<{ onUpdateXeroIntegration: VoidFunction }> = ({ onUpdateXeroIntegration }) => {
  return (
    <Popover
      trigger={["click"]}
      placement={"bottomRight"}
      rootClassName={"notifications-popover"}
      content={<NotificationsContent onUpdateXeroIntegration={onUpdateXeroIntegration} />}
    >
      <BellOutlined style={{ fontSize: 18 }} />
    </Popover>
  )
}
