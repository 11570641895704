export const getInitials = (contactName: string): string => {
  return contactName
    .split(" ")
    .map(word => {
      const firstChar = word.charAt(0)
      if (/[a-zA-Z]/.test(firstChar)) {
        return firstChar.toUpperCase()
      }
      return ""
    })
    .join("")
}