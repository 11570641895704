import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Button, Modal, ModalProps, Row, Typography } from "antd"
import { FC } from "react"
import { VSpace } from "../../ui/VerticalSpace"

const { Title, Paragraph } = Typography

export const UpdateIntegrationModal: FC<ModalProps> = ({ ...props }) => {
  const handleUpdateXeroTokens = () => {}

  return (
    <Modal
      title={
        <Row justify={"center"} align={"middle"}>
          <ExclamationCircleOutlined
            style={{ fontSize: 28, background: "#FAAD14", color: "#fff", borderRadius: "50%" }}
          />
        </Row>
      }
      footer={null}
      centered
      {...props}
    >
      <VSpace size={16}>
        <Title style={{ fontSize: 16, textAlign: "center" }}>Update Xero Integration Tokens</Title>
        <VSpace size={24} align={"center"}>
          <Paragraph style={{ color: "#8A959E", textAlign: "center" }}>
            Your Xero integration needs attention! To ensure uninterrupted service, kindly update your access tokens.
          </Paragraph>
          <Button size={"large"} type={"primary"} onClick={handleUpdateXeroTokens}>
            Update
          </Button>
        </VSpace>
      </VSpace>
    </Modal>
  )
}
