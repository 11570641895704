import { Button, Row, Typography } from "antd"
import { FC } from "react"

import { VSpace } from "../../../ui/VerticalSpace"
import { NotificationCard } from "../notification-card"
import { UpdateIntegrationMessage } from "../update-integration-message"
import "./index.less"

const { Title } = Typography
export const NotificationsContent: FC<{ onUpdateXeroIntegration: VoidFunction }> = ({ onUpdateXeroIntegration }) => (
  <VSpace size={0} className={"notifications-content"}>
    <Row align={"middle"} justify={"space-between"} className={"notifications-header"}>
      <Title level={4}>Notification</Title>
      <Button type={"link"}>Mark all as read</Button>
    </Row>

    <VSpace size={0} className={"notifications-body"}>
      <UpdateIntegrationMessage onUpdateXeroIntegration={onUpdateXeroIntegration} />

      <NotificationCard
        title={"Job-1"}
        description={"Competently pursue clicks-and-mortar information before next-generation innovation."}
        timestamp={"2 days ago"}
        avatarUrl={""}
        loading={false}
      />
      <NotificationCard
        title={"Job-1"}
        description={"Competently pursue clicks-and-mortar information before next-generation innovation."}
        timestamp={"2 days ago"}
        avatarUrl={""}
        loading={false}
      />
    </VSpace>
  </VSpace>
)
