import { UserOutlined } from "@ant-design/icons"
import { Avatar, Badge, Card, Row, Typography } from "antd"
import { FC } from "react"

import { VSpace } from "../../../ui/VerticalSpace"
import "./index.less"

const { Title, Paragraph } = Typography

type NotificatioCardProps = {
  title: string
  description: string
  timestamp: string
  avatarUrl: string
  loading: boolean
}
export const NotificationCard: FC<NotificatioCardProps> = ({ title, description, timestamp, avatarUrl, loading }) => (
  <Card rootClassName={"notification-card"} bordered={false} loading={loading}>
    <Card.Meta
      avatar={<Avatar src={avatarUrl} icon={<UserOutlined />} />}
      title={
        <Row justify={"space-between"} align={"middle"}>
          <Title className={"card-title"}>{title}</Title>
          <Badge color={"#FF4D4F"} />
        </Row>
      }
      description={
        <VSpace>
          <Paragraph className={"card-description"}>{description}</Paragraph>
          <span className={"card-timestamp"}>{timestamp}</span>
        </VSpace>
      }
    />
  </Card>
)
