import { FC } from "react"
import { Input, InputProps } from "antd"

import { ReactComponent as SearchIcon } from "src/icons/search-icon.svg"
import { ReactComponent as CloseIcon } from "src/icons/close.svg"

import './index.less'

export const GlobalSearchInput: FC<InputProps & {onClearSearch: () => void}> = ({onClearSearch, ...props}) => {
  return (
    <Input
      rootClassName={"global-search-input"}
      variant={"outlined"}
      placeholder={"Search"}
      prefix={<SearchIcon />}
      allowClear={{clearIcon: <span onClick={onClearSearch}><CloseIcon /></span>}}
      {...props}
    />
  )
}
