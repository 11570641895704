import { Col, Row } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"

import { ReactComponent as MobileLogoLightTheme } from "src/icons/mobile-logo-dark.svg"
import { HeaderMenu } from "../HeaderMenu"

export const DefaultHeader: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  return (
    <Row align={"middle"} justify={"start"}>
      <Col>
        <Link to={"/"} style={{display: "flex", alignItems: "center"}}>
          <MobileLogoLightTheme />
        </Link>
      </Col>
      <Col>
        <HorizontalMenu />
      </Col>
    </Row>
  )
}
