import { Layout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Loader } from "src/components/ui/Loader"
import { AuthHeader } from "./ui/AuthHeader"
import { AuthSection } from "./ui/AuthSection"

import "./index.less"

const AuthLayout: FC = () => (
  <Layout>
    <Layout.Header className={"auth-header"}>
      <AuthHeader />
    </Layout.Header>
    <Layout>
      <AuthSection>
        <Suspense fallback={<Loader size={"large"} spinning />}>
          <Outlet />
        </Suspense>
      </AuthSection>
    </Layout>
  </Layout>
)

export { AuthLayout }
