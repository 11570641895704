import { FC, useState } from "react"
import { AutoComplete } from "antd"

import './index.less'
import { useGlobalSearchData, OptionType } from "../../../hooks/useGlobalSearchData"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { GlobalSearchInput } from "../global-search-input"

export const GlobalSearchAutocomplete: FC = () => {
  const { isMobile } = useBreakpoints()
  const [open, setOpen] = useState(false)
  const { searchValue, options, updateRecentSearches, onChangeSearch, onClearSearch} = useGlobalSearchData()

  return (
    <AutoComplete
      open={isMobile ? false : open}
      options={searchValue ? options : []}
      popupMatchSelectWidth={true}
      className={'global-search-autocomplete'}
      popupClassName={'global-search-autocomplete-dropdown'}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      onChange={() => setOpen(true)}
      onSelect={(value: any, option: any) => {
        updateRecentSearches({value: option.value, key: option.key, label: value})
        setOpen(false)
      }}
    >
      <GlobalSearchInput
        value={searchValue}
        onChange={onChangeSearch}
        onClearSearch={onClearSearch}
      />
    </AutoComplete>
  )
}
