import { Layout } from "antd";
import { FC, Suspense, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useBreakpoints } from "src/hooks/useBreakpoints";
import { Loader } from "../../ui/Loader";
import { DashboardHeader } from "../DashboardHeader";
import { SideBarMenu } from "../SideBarMenu";
import "./index.less";
import { useOrganizationQuery } from "src/graphql"

const { Sider, Header } = Layout;

const RegisteredLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { isMobile, isTabletMD } = useBreakpoints();
  const navigate = useNavigate();

  const isProfilePage = location.pathname === "/profile";
  const shouldShowHeader = isProfilePage ? isTabletMD : true;
  const { data, loading } = useOrganizationQuery();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkSubscription = async () => {
      if (loading) return;

      const sessionId = searchParams.get("session_id");
      const success = searchParams.get("success");

      if (success || sessionId) return;

      if (!data?.organization?.subscription?.data?.attributes?.stripeID) {
        navigate("/welcome");
      }
    };

    checkSubscription();
  }, [data, loading, navigate, searchParams]);

  if (loading) return <Loader size={"large"} spinning />;

  return (
    <Layout hasSider={!isMobile} style={{ minHeight: "100vh" }}>
      {!isMobile && (
        <Sider
          collapsible
          className={"authenticated-slider"}
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          width={205}
          style={{ padding: "40px 28px", overflow: "hidden" }}
        >
          <SideBarMenu />
        </Sider>
      )}
      <Layout.Content className={"authenticated-layout"}>
        <div className={"authenticated-layout-content"}>
          {shouldShowHeader && (
            <Header className={"custom-header"}>
              <DashboardHeader />
            </Header>
          )}
          <Suspense fallback={<Loader size={"large"} spinning />}>
            <Outlet />
          </Suspense>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export { RegisteredLayout };